import loadable from "@loadable/component"
import { graphql } from "gatsby"
import React from "react"
import { isBrowser, slugify } from "../../../utils"
import ProductFilter from "../../organisms/product/ProductFilter"
//import * as styles from "./DatoProductFilter.module.scss"

const StaticDatoProductFilter = loadable(() => import("../../molecules/StaticDatoProductFilter"))

export type DatoProductFilterProps = { title: string; text: string; queryParams: string | null }

function isObject(val: unknown): val is object {
  return val !== null && typeof val === "object"
}

const DatoProductFilter: React.FC<DatoProductFilterProps> = props => {
  //console.log("Rendering DatoProductFilter", props)

  const params = props.queryParams ? JSON.parse(props.queryParams) : undefined

  /**
   * Online haku suoraan rajapinnasta.
   */
  const query = isObject(params)
    ? Object.keys(params)
        .map(key => {
          // value voi olla pilkulla eroteltuja arvoja, jolloin jaetaan se useiksi hakupareiksi
          // esim: {"product_family":"cilindro,forte"} => "product_family:cilindro,product_family:forte"
          const values = params[key].split(",")
          return values.map((val: string) => `${key}:${val.trim()}`).join(",")
        })
        .join()
    : undefined

  return (
    <div id={slugify(props.title)} className="layout-container">
      {isBrowser ? (
        <ProductFilter title={props.title} description={props.text} query={query} />
      ) : (
        // Loadable component: Renderoi staattisesti hakutuloksia osasta queryParams valinnoista
        <StaticDatoProductFilter title={props.title} description={props.text} params={params} />
      )}
    </div>
  )
}

export default DatoProductFilter

export const query = graphql`
  fragment DatoProductFilter on DatoCmsProductFilter {
    id: originalId # Korjaus daton bugiin, jos käytetään structured textissä
    title
    text
    queryParams
  }
`
